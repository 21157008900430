<template>
  <div class="mt-1">
    <vue-skeleton-loader
      :width="720"
      :height="145"
      class="mb-6"
    />
    <vue-skeleton-loader
      v-for="n in 9"
      :key="n"
      class="mt-1"
      :width="720"
      :height="50"
    />
  </div>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  components: {
    VueSkeletonLoader,
  },

}
</script>

<style>

</style>
