<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ action === 'Save' ? 'Add' : 'Update' }} System User</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="saveOrUpdate()"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.name"
                    label="Full Name"
                    placeholder="John Doe"
                    :rules="nameRules"
                    :error="form.errors.has('name')"
                    :error-messages="form.errors.get('name')"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.email"
                    label="Email"
                    placeholder="example@domain.com"
                    :error="form.errors.has('email')"
                    :error-messages="form.errors.get('email')"
                    :rules="emailRules"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-select
                    v-model="form.role"
                    :items="roles"
                    label="Role"
                    :rules="[v => !!v || 'Role is required']"
                    :error="form.errors.has('role')"
                    :error-messages="form.errors.get('role')"
                    :loading="isRolesLoading"
                    outlined
                  ></v-select>
                  <small
                    v-show="form.errors.has('role')"
                    class="validation-error"
                  >{{
                    form.errors.get('role')
                  }}</small>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import { mapGetters } from 'vuex'
import axios from 'axios'
import validationRules from '@/mixins/validationRules'

export default {
  mixins: [validationRules],
  props: {
    showDialog: {
      type: Boolean,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: new Form({
      name: '',
      email: '',
      role: '',
      user_type: '',
    }),
    isRolesLoading: false,
    roles: [],
    action: 'Save',
  }),
  computed: {
    ...mapGetters(['selectedStation']),
  },
  watch: {
    user() {
      if (Object.keys(this.user).length > 0) {
        this.form.update(this.user)
        this.action = 'Update'
      } else {
        this.action = 'Save'
      }
    },
    showDialog(value) {
      if (value && this.roles.length === 0) {
        this.getRoles()
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close')
      this.$refs.form.reset()
    },
    getRoles() {
      this.isRolesLoading = true
      axios
        .get('roles')
        .then(response => {
          this.roles = response.data
          this.isRolesLoading = false
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
          this.isRolesLoading = false
        })
    },
    saveOrUpdate() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        if (this.action === 'Save') {
          this.saveUser()
        } else {
          this.updateUser()
        }
      }
    },
    saveUser() {
      this.form
        .post('system-users')
        .then(() => {
          this.$toast.success('System User added successfully')
          const userEmail = this.form.email
          setTimeout(() => { this.$toast.success(`An email has been sent to ${userEmail} with details on how to set up their account. `) }, 1500)
          this.$emit('close')
          this.$emit('user-added')
          this.$refs.form.reset()
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
    },
  },
}
</script>
