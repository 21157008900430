<template>
  <v-card
    flat
    class="mt-5 full-height"
  >
    <div class="px-3">
      <h1 class="text-center">
        System Settings
      </h1>
      <v-container>
        <v-row
          v-if="!isSettingsLoaded"
          justify="center"
          align="center"
          class="full-height"
        >
          <v-progress-linear
            v-if="!isSettingsLoaded"
            indeterminate
            color="primary"
          ></v-progress-linear>
        </v-row>
        <div v-else>
          <settings-tabs
            :settings="settings"
            :errors="errors"
          ></settings-tabs>
        </div>
      </v-container>
    </div>
  </v-card>
</template>

<script>
import axios from 'axios'
import SettingsTabs from '@/components/partials/SettingsTabs.vue'

export default {
  components: {
    SettingsTabs,
  },
  data() {
    return {
      isSettingsLoaded: false,
      settings: {},
      errors: {},
    }
  },
  mounted() {
    this.fetchSettings()
  },
  methods: {
    fetchSettings() {
      axios.get('settings')
        .then(response => {
          this.settings = response.data
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            console.log(error)
            this.$toast.error(error.response.data.message)
          }
        }).finally(() => {
          this.isSettingsLoaded = true
        })
    },
  },
}
</script>

<style scoped>
.full-height {
  min-height: 90vh; /* Set the minimum height to the viewport height */
}
</style>
