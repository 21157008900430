<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ action === 'Save' ? 'Add' : 'Update' }} Role</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="saveOrUpdate()"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.name"
                    label="Role Name"
                    placeholder="manager"
                    :rules="nameRules"
                    :readonly="isReadOnlyRole"
                    outlined
                    required
                  ></v-text-field>
                  <small
                    v-show="form.errors.has('name')"
                    class="validation-error"
                  >{{ form.errors.get('name') }}</small>
                </v-col>
                <v-col cols="12">
                  <label class="body-2">Permissions</label>
                  <permissions-shimmer v-if="isRolesLoading" />
                  <v-expansion-panels
                    v-else
                    v-model="panel"
                    multiple
                    class=" mt-6"
                  >
                    <v-expansion-panel
                      v-for="role in form.permissions"
                      :key="role.name"
                    >
                      <v-expansion-panel-header class="text-capitalize">
                        {{ role.name === 'user' ? 'customer' : role.name }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col v-if="permissionCan(role.name, 'list')">
                            <v-checkbox
                              v-model="role.list"
                              label="View"
                            ></v-checkbox>
                          </v-col>
                          <v-col v-if="permissionCan(role.name, 'create')">
                            <v-checkbox
                              v-model="role.create"
                              label="Create"
                            ></v-checkbox>
                          </v-col>
                          <v-col v-if="permissionCan(role.name, 'edit')">
                            <v-checkbox
                              v-model="role.edit"
                              label="Edit"
                            ></v-checkbox>
                          </v-col>
                          <v-col v-if="permissionCan(role.name, 'delete')">
                            <v-checkbox
                              v-model="role.delete"
                              label="Delete"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import { serialize } from 'object-to-formdata'
import { mapGetters } from 'vuex'
import axios from 'axios'
import validationRules from '@/mixins/validationRules'
import PermissionsShimmer from '@/components/partials/shimmers/PermissionsShimmer.vue'

export default {
  components: {
    PermissionsShimmer,
  },
  mixins: [validationRules],
  props: {
    showDialog: {
      type: Boolean,
    },
    role: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    panel: [0],
    form: new Form({
      name: '',
      permissions: [],
    }),
    isRolesLoading: true,
    action: 'Save',
    permissionsWithViewOnly: ['payment', 'analytics'],
    permissionsWithViewAndDeleteOnly: ['unresolved mpesa payment'],
    permissionsWithViewCreateAndDeleteOnly: ['sms', 'system-user'],
  }),
  computed: {
    ...mapGetters(['selectedStation']),
    isReadOnlyRole() {
      return this.role === 'admin' || this.role === 'technician'
    },
  },
  watch: {
    role() {
      if (this.role.length > 0) {
        this.form.update({ name: this.role })
        this.getRolePermissions(this.form.name)
        this.action = 'Update'
      } else {
        this.action = 'Save'
      }
    },
    showDialog(value) {
      if ((value && this.form.permissions.length === 0) && this.role.length === 0) {
        this.getPermissionModels()
      }
    },
  },
  methods: {
    permissionCan(permission, action) {
      if (action === 'list') {
        return true
      }

      if (this.permissionsWithViewAndDeleteOnly.includes(permission)) {
        return action === 'delete'
      }

      if (this.permissionsWithViewOnly.includes(permission)) {
        return action === 'list'
      }

      if (this.permissionsWithViewCreateAndDeleteOnly.includes(permission)) {
        return action === 'create' || action === 'delete'
      }

      return true
    },
    closeDialog() {
      this.$emit('close')
      this.$refs.form.reset()
      this.form.permissions = []
    },
    getPermissionModels() {
      this.isRolesLoading = true
      axios
        .get('permission-models')
        .then(response => {
          this.permissions = response.data
          response.data.forEach(role => {
            const roleInfo = {
              name: role, list: false, create: false, edit: false, delete: false,
            }
            this.form.permissions.push(roleInfo)
          })
          this.isRolesLoading = false
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
          this.isRolesLoading = false
        })
    },
    getRolePermissions(role) {
      this.isRolesLoading = true
      axios
        .get(`roles/${role}`)
        .then(response => {
          const roleInfo = []
          response.data.forEach(data => {
            const modelActions = { name: data.name }
            data.actions.forEach(action => {
              modelActions[action] = true
            })
            roleInfo.push(modelActions)
          })
          this.form.permissions = roleInfo
          this.isRolesLoading = false
        })
        .catch(error => {
          console.log(error)
          this.$toast.error(error.response.data.message)
          this.isRolesLoading = false
        })
    },
    saveOrUpdate() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        let
          message; let appendUrl
        if (this.action === 'Save') {
          message = 'added'
          appendUrl = ''
        } else {
          message = 'updated'
          appendUrl = `-update/${this.role}`
        }
        this.form
          .submit('post', `roles${appendUrl}`, {
          // Transform form objects to FormData
            transformRequest: [
            // eslint-disable-next-line no-unused-vars
              function (data, headers) {
                const { permissions } = data
                delete data.permissions
                const serializedData = serialize(data)
                serializedData.append('permissions', JSON.stringify(permissions))

                return serializedData
              },
            ],
          })
          .then(() => {
            this.$toast.success(`Role ${message} successfully`)
            this.$emit('close')
            this.$emit('role-added')
            this.$refs.form.reset()
          })
          .catch(error => {
            this.$toast.error(error.response.data.message)
          })
      }
    },
  },
}
</script>
