<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <h1 class="text-center">
      Billing
    </h1>
    <v-card-text class="mt-5">
      <h4 class="mb-2">
        Billing history
      </h4>
      <v-data-table
        :headers="headers"
        :loading="isInvoicesLoading"
        :items="invoices"
        :server-items-length="pagination.totalItems"
        :options.sync="options"
        :footer-props="footerProps"
        class="elevation-1"
      >
        <template v-slot:item.invoice_number="{ item }">
          <span>{{ item.invoice_number }}</span>
        </template>
        <template v-slot:item.created_at="{ item }">
          <span>{{ item.created_at | formatDay }}</span>
        </template>
        <template v-slot:item.due_date="{ item }">
          <span>{{ item.due_date | formatDay }}</span>
        </template>
        <template v-slot:item.amount="{ item }">
          <span>Ksh {{ item.total_amount | formatCurrency }}</span>
        </template>
        <template v-slot:item.users="{ item }">
          <span>{{ item.monthly_active_customer.total_active_users }} customers</span>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip :color="getChipColor(item.status)">
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            class="ma-2"
            small
            outlined
            text
            :loading="viewingInvoicesLoading[item.id]"
            :disabled="viewingInvoicesLoading[item.id]"
            @click="viewInvoice(item.id)"
          >
            <v-icon
              class="mr-2"
              left
            >
              {{ icons.mdiEyeOutline }}
            </v-icon>
            View
          </v-btn>
          <v-btn
            class="ma-2"
            small
            outlined
            text
            :loading="loadingInvoices[item.id]"
            :disabled="loadingInvoices[item.id]"
            @click="downloadInvoice(item.id)"
          >
            <v-icon
              class="mr-2"
              left
            >
              {{ icons.mdiDownload }}
            </v-icon>
            Download
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'
import { mdiDownload, mdiEyeOutline } from '@mdi/js'

export default {
  data() {
    return {
      headers: [
        { text: 'Invoice', value: 'invoice_number' },
        { text: 'Date issued', value: 'created_at' },
        { text: 'Due date', value: 'due_date' },
        { text: 'Amount', value: 'amount' },
        { text: 'Active customers', value: 'users' },
        { text: 'Status', value: 'status' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      isInvoicesLoading: true,
      invoices: [],
      loadingInvoices: {},
      viewingInvoicesLoading: {},
      pagination: {
        totalItems: 0,
      },
      options: {
        sortBy: ['created_at'],
        sortDesc: [true],
        itemsPerPage: 10,
      },
      footerProps: {
        'items-per-page-options': [10, 25, 50, 100],
        'items-per-page-text': 'Items per page:',
      },
      icons: {
        mdiDownload,
        mdiEyeOutline,
      },
    }
  },
  computed: {
    constructCompleteServiceChargeInvoicesUrl() {
      const params = new URLSearchParams({
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        sortBy: this.options.sortBy,
        sortDirection: this.options.sortDesc[0] ? 'desc' : 'asc',
      })

      return `service-charge-invoices?${params.toString()}`
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchInvoices()
      },
      deep: true,
    },
  },
  methods: {
    fetchInvoices() {
      this.isInvoicesLoading = true
      axios.get(this.constructCompleteServiceChargeInvoicesUrl).then(response => {
        this.invoices = response.data.data
        this.pagination.totalItems = response.data.total
      }).finally(() => {
        this.isInvoicesLoading = false
      })
    },
    getChipColor(status) {
      switch (status) {
        case 'Unpaid':
          return '#9E9E9E' // Grey
        case 'Paid':
          return '#4CAF50' // Green
        case 'Overdue':
          return '#F44336' // Red
        case 'Partially Paid':
          return '#FFC107' // Yellow
        default:
          return ''
      }
    },
    viewInvoice(id) {
      this.$set(this.viewingInvoicesLoading, id, true)
      axios.get(`/service-charge-invoices/${id}/download`, { responseType: 'blob' })
        .then(response => {
          const pdfBlob = response.data
          const pdfUrl = URL.createObjectURL(pdfBlob)
          window.open(pdfUrl, '_blank')
        })
        .catch(error => {
          console.error('Error viewing invoice:', error)
          this.$toast.error('An error occurred while loading the invoice')
        }).finally(() => {
          this.$set(this.viewingInvoicesLoading, id, false)
        })
    },
    downloadInvoice(id) {
      this.$set(this.loadingInvoices, id, true)
      const url = `/service-charge-invoices/${id}/download`

      axios({
        method: 'GET',
        url,
        responseType: 'blob',
      }).then(response => {
        const blob = new Blob([response.data], { type: response.headers['content-type'] })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)

        const contentDisposition = response.headers['content-disposition'] || response.headers['Content-Disposition']

        let filename = 'invoice.pdf' // default filename

        if (contentDisposition) {
          const filenameRegex = /filename="?([^"]+)"?\b/
          const matches = contentDisposition.match(filenameRegex)
          if (matches && matches[1]) {
            filename = matches[1]
          }
        }

        link.download = filename
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(error => {
        console.error('There was an error downloading the invoice:', error)
        this.$toast.error('There was an error downloading the invoice')
      }).finally(() => {
        this.$set(this.loadingInvoices, id, false)
      })
    },
  },
}
</script>
