<template>
  <div class="role-settings pb-10">
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <h1 class="text-center">
        Roles
      </h1>
      <p class="text-center">
        Manage system users roles and permissions.
      </p>
      <v-row>
        <v-spacer></v-spacer>
        <v-col
          class="text-right mt-3 mr-8"
          cols="6"
        >
          <v-btn
            v-if="can('role-create')"
            color="primary"
            @click="showAddRoleDialog = true"
          >
            <v-icon
              left
              size="22"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            Add Role
          </v-btn>
        </v-col>
      </v-row>
      <v-card-title>
        Roles
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="roles"
        :loading="isRolesLoading"
      >
        <template v-slot:item.role="{ item }">
          {{ item.role }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip
            v-if="can('role-edit')"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                ma-2
                v-bind="attrs"
                text
                icon
                @click.stop="editRole(item.role)"
                v-on="on"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiPencil }}
                </v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip
            v-if="can('role-delete')"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                ma-2
                v-bind="attrs"
                text
                icon
                @click.stop="showDeleteRoleDialog(item.role)"
                v-on="on"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiDelete }}
                </v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider />
    </v-card>
    <add-role-dialog
      :show-dialog="showAddRoleDialog"
      :role="role"
      @close="
        showAddRoleDialog = false
        role = ''
      "
      @role-added="getRoles()"
    />
    <confirm-dialog
      :show-dialog="showConfirmDeleteDialog"
      :is-agree-button-loading="isConfirmDeleteDialogButtonLoading"
      :agree-text="'Delete'"
      :message="deleteRoleMessage"
      @cancel="showConfirmDeleteDialog = false"
      @agree="deleteRole()"
    />
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import {
  mdiPencil, mdiDelete, mdiPlus,
} from '@mdi/js'
import hasPermission from '@/mixins/hasPermission'
import AddRoleDialog from '@/components/dialogs/AddRoleDialog.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'

export default {
  components: {
    AddRoleDialog,
    ConfirmDialog,
  },
  mixins: [hasPermission],
  data() {
    return {
      headers: [
        { text: 'Role', value: 'role', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      isLoading: true,
      pagination: {
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      footerProps: {
        itemsPerPageOptions: [5, 10, 25, 50, 100],
      },
      options: {
        sortBy: ['created_at'],
        sortDesc: [true],
      },
      role: '',
      roles: [],
      roleUrl: 'roles',
      isRolesLoading: false,
      showAddRoleDialog: false,
      showConfirmDeleteDialog: false,
      isConfirmDeleteDialogButtonLoading: false,
      toDelete: null,
      toDeleteRole: null,
      icons: {
        mdiPencil,
        mdiDelete,
        mdiPlus,
      },
    }
  },
  computed: {
    deleteRoleMessage() {
      return `Are you sure you want to delete ${this.toDeleteRole} from roles`
    },
  },
  mounted() {
    this.getRoles()
  },
  methods: {
    editRole(role) {
      this.role = role
      this.showAddRoleDialog = true
    },
    showDeleteRoleDialog(role) {
      this.showConfirmDeleteDialog = true
      this.toDelete = role
      this.toDeleteRole = role
    },
    getRoles: _.debounce(function () {
      this.isRolesLoading = true
      axios
        .get(
          `${this.roleUrl}`,
        )
        .then(response => {
          this.roles = response.data.map(role => ({ role }))
          this.isRolesLoading = false
        })
        .catch(error => {
          this.isRolesLoading = false
          this.$toast.error(error.response.data.message)
        })
    }, 500),
    deleteRole() {
      this.isConfirmDeleteDialogButtonLoading = true
      axios
        .delete(`roles/${this.toDelete}`)
        .then(() => {
          this.$toast.success('Role deleted successfully')
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
          this.getRoles()
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
        })
    },
    onPageChange() {
      this.getRoles()
    },
  },
}
</script>

<style>
.clickable {
  cursor: pointer;
}
</style>
