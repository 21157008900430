<template>
  <div class="settings-tabs">
    <v-tabs vertical>
      <v-tab
        v-for="(categorySettings, category) in visibleSettings"
        :key="category"
      >
        {{ toSentenceCase(category) }}
      </v-tab>
      <v-tab-item
        v-for="(categorySettings, category, index) in visibleSettings"
        :key="category"
        transition="scroll-y-transition"
      >
        <v-row>
          <v-col cols="12">
            <v-expand-transition>
              <v-card
                class="ml-2"
                flat
              >
                <v-card-text>
                  <h2 class="font-weight-medium">
                    {{ toSentenceCase(category) }}
                  </h2>
                </v-card-text>
                <template
                  v-if="category === 'notification_messages'"
                >
                  <v-card-text>
                    <p v-if="getDescriptionSetting(categorySettings)">
                      {{ getDescriptionSetting(categorySettings).value }}
                    </p>
                    <v-expansion-panels
                      v-model="expandedPanel"
                    >
                      <v-expansion-panel
                        v-for="(setting, settingIndex) in categorySettings"
                        v-if="setting.input_type === 'label'"
                        :key="setting.id"
                      >
                        <v-expansion-panel-header>
                          {{ setting.value }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-col
                            v-for="(subSetting, subSettingIndex) in getSubSettings(categorySettings, settingIndex)"
                            :key="subSetting.id"
                            :cols="12"
                          >
                            <v-select
                              v-if="subSetting.input_type === 'dropdown'"
                              v-model="subSetting.value"
                              :label="toSentenceCase(subSetting.key)"
                              :items="subSetting.options"
                              :hint="subSetting.hint"
                              :persistent-hint="subSetting.persistent_hint"
                              :error-messages="errors[`settings.${index}.value`]"
                              outlined
                            ></v-select>
                            <v-textarea
                              v-else-if="subSetting.input_type === 'textarea'"
                              :value="getTextareaValue(subSetting)"
                              :label="toSentenceCase(subSetting.key)"
                              :hint="subSetting.hint"
                              :persistent-hint="subSetting.persistent_hint"
                              :error-messages="errors[`settings.${index}.value`]"
                              outlined
                              auto-grow
                              @input="value => updateTextareaValue(subSetting, value)"
                            />
                          </v-col>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-card-text>
                </template>
                <v-card-text
                  v-else
                  class="pt-1"
                >
                  <v-row>
                    <template
                      v-for="(setting, settingIndex) in categorySettings"
                    >
                      <v-col
                        :key="setting.id"
                        :cols="setting.width || 12"
                      >
                        <div
                          class="d-flex"
                          :class="setting.css_class"
                        >
                          <p v-if="setting.input_type === 'paragraph'">
                            {{ setting.value }}
                          </p>
                          <v-label
                            v-else-if="setting.input_type === 'label'"
                            :label="toSentenceCase(setting.key)"
                            :hint="setting.hint"
                            :error-messages="errors[`settings.${index}.value`]"
                            outlined
                          >
                            {{ setting.value }}
                          </v-label>
                          <v-text-field
                            v-if="setting.input_type === 'text' || setting.input_type === 'number' || setting.input_type === 'email'"
                            v-model="setting.value"
                            :label="toSentenceCase(setting.key)"
                            :type="setting.input_type"
                            :hint="setting.hint"
                            :persistent-hint="setting.persistent_hint"
                            :error-messages="errors[`settings.${index}.value`]"
                            outlined
                          ></v-text-field>
                          <span v-else-if="setting.input_type === 'textarea'">
                            <v-textarea
                              v-if="setting.is_value_json_encoded"
                              :value="setting.value[getNotificationMessageValue(settingIndex)]"
                              :label="toSentenceCase(setting.key)"
                              :hint="setting.hint"
                              :persistent-hint="setting.persistent_hint"
                              :error-messages="errors[`settings.${index}.value`]"
                              outlined
                              auto-grow
                              @input="value => { setting.value[getNotificationMessageValue(settingIndex)] = value }"
                            />
                            <v-textarea
                              v-else
                              v-model="setting.value"
                              :label="toSentenceCase(setting.key)"
                              :hint="setting.hint"
                              :persistent-hint="setting.persistent_hint"
                              :error-messages="errors[`settings.${index}.value`]"
                              outlined
                              auto-grow
                            />
                          </span>
                          <v-autocomplete
                            v-else-if="setting.input_type === 'autocomplete'"
                            v-model="setting.value"
                            :label="toSentenceCase(setting.key)"
                            :items="setting.options"
                            :hint="setting.hint"
                            :persistent-hint="setting.persistent_hint"
                            :error-messages="errors[`settings.${index}.value`]"
                            outlined
                          ></v-autocomplete>
                          <v-select
                            v-else-if="setting.input_type === 'dropdown'"
                            v-model="setting.value"
                            :label="toSentenceCase(setting.key)"
                            :items="setting.options"
                            :hint="setting.hint"
                            :persistent-hint="setting.persistent_hint"
                            :error-messages="errors[`settings.${index}.value`]"
                            outlined
                          ></v-select>
                          <v-file-input
                            v-else-if="setting.input_type === 'photo'"
                            v-model="setting.value"
                            :label="toSentenceCase(setting.key)"
                            :hint="setting.hint"
                            :persistent-hint="setting.persistent_hint"
                            :error-messages="errors[`settings.${index}.value`]"
                            outlined
                            show-size
                            accept="image/*"
                          ></v-file-input>
                          <v-switch
                            v-else-if="setting.input_type === 'switch'"
                            v-model="setting.value"
                            :label="toSentenceCase(setting.key)"
                            :hint="setting.hint"
                            :persistent-hint="setting.persistent_hint"
                            :error-messages="errors[`settings.${index}.value`]"
                          />
                          <v-checkbox
                            v-else-if="setting.input_type === 'checkbox'"
                            v-model="setting.value"
                            :hint="setting.hint"
                            :persistent-hint="setting.persistent_hint"
                            :error-messages="errors[`settings.${index}.value`]"
                            :label="toSentenceCase(setting.key)"
                          ></v-checkbox>
                          <v-tooltip
                            v-if="setting.description"
                            max-width="300"
                            bottom
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                small
                                color="primary"
                                class="ml-2"
                                :class="{ 'mt-6': setting.input_type === 'checkbox', 'mt-4': setting.input_type !== 'checkbox' }"
                                v-bind="attrs"
                                style="cursor: pointer;"
                                v-on="on"
                              >
                                {{ icons.mdiInformationOutline }}
                              </v-icon>
                            </template>
                            <span>{{ setting.description }}</span>
                          </v-tooltip>
                        </div>
                      </v-col>
                    </template>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          class="text-end mt-2"
        >
          <v-btn
            color="primary"
            :loading="isSavingSettings"
            @click="saveSettings"
          >
            Save Changes
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import { mdiInformationOutline } from '@mdi/js'
import { mapActions } from 'vuex'
import axios from 'axios'

export default {
  props: {
    settings: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expandedPanel: null,
      icons: {
        mdiInformationOutline,
      },
      isSavingSettings: false,
    }
  },
  computed: {
    visibleSettings() {
      const visibleSettingsObj = {}
      for (const [category, categorySettings] of Object.entries(this.settings)) {
        visibleSettingsObj[category] = categorySettings.filter(setting => {
          if (setting.visibility_condition === null) {
            return true // Always visible when visibility_condition is null
          }
          const conditions = JSON.parse(setting.visibility_condition)

          return Object.entries(conditions).every(([key, value]) => {
            const conditionSetting = categorySettings.find(s => s.key === key)
            if (conditionSetting) {
            // Convert the condition value to a boolean if it's a number (0 or 1)
              const conditionValue = typeof value === 'number' ? Boolean(value) : value

              // Convert the setting value to a boolean if it's a number (0 or 1)
              const settingValue = typeof conditionSetting.value === 'number' ? Boolean(conditionSetting.value) : conditionSetting.value

              return settingValue === conditionValue
            }

            return false
          })
        })
      }

      return visibleSettingsObj
    },
  },
  methods: {
    ...mapActions([
      'setCompanyName',
    ]),
    saveSettings() {
      this.isSavingSettings = true
      const settingsArray = Object.values(this.settings).flat()
      axios.put('settings', { settings: settingsArray })
        .then(() => {
          this.$toast.success('Updated successfully')
          settingsArray.forEach(setting => {
            if (setting.key === 'company_name') {
              this.setCompanyName(setting.value)
            }
          })
        })
        .catch(error => {
          console.log(error)
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.isSavingSettings = false
        })
    },
    toSentenceCase(text) {
      return text
        .replace(/_/g, ' ')
        .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
    },
    getSubSettings(categorySettings, settingIndex) {
      const subSettings = []
      let index = settingIndex + 1

      while (index < categorySettings.length && categorySettings[index].input_type !== 'label') {
        subSettings.push(categorySettings[index])
        index++
      }

      return subSettings
    },
    getTextareaValue(subSetting) {
      const selectedTone = this.settings.notification_messages.find(
        setting => setting.input_type === 'dropdown' && setting.name === subSetting.name,
      )?.value

      return subSetting.value[selectedTone] || ''
    },

    updateTextareaValue(subSetting, value) {
      const selectedTone = this.settings.notification_messages.find(
        setting => setting.input_type === 'dropdown' && setting.name === subSetting.name,
      )?.value

      if (selectedTone) {
        subSetting.value[selectedTone] = value
      }
    },
    getDescriptionSetting(categorySettings) {
      return categorySettings.find(
        setting => setting.input_type === 'paragraph' && setting.category === 'notification_messages',
      )
    },
    getNotificationMessageValue(settingIndex) {
      console.log('🚀 ~ getNotificationMessageValue ~ settingIndex:', settingIndex)
      let index = settingIndex !== 1 ? (settingIndex - 1) : settingIndex

      // Continue decrementing the index while the value at that index is an object.
      while (typeof this.settings.notification_messages[index].value === 'object' && index >= 0) {
        index--
      }

      // Return the string value or undefined if not found.
      const notificationMessageValue = (typeof this.settings.notification_messages[index].value === 'string') ? this.settings.notification_messages[index].value : undefined

      return notificationMessageValue
    },
  },
}
</script>

<!-- Keep the styles scoped -->
<style scoped>
  /* Any specific styles for this component */
</style>
