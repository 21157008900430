<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.path"
        :href="'#' + tab.path"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item value="account">
        <account-settings />
      </v-tab-item>

      <v-tab-item value="security">
        <security-settings />
      </v-tab-item>

      <v-tab-item
        v-if="can('system-setting-list')"
        value="system-settings"
      >
        <system-settings />
      </v-tab-item>

      <v-tab-item
        v-if="can('service-charge-invoice-list')"
        value="service-charge-invoice"
      >
        <billing />
      </v-tab-item>

      <v-tab-item
        v-if="can('system-user-list')"
        value="system-users"
      >
        <user-settings></user-settings>
      </v-tab-item>

      <v-tab-item
        v-if="can('role-list')"
        value="roles"
      >
        <roles-settings />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import {
  mdiAccountOutline, mdiLockOpenOutline, mdiApplicationCog, mdiInformationOutline, mdiAccountMultiple, mdiAdjust, mdiBookmark,
} from '@mdi/js'
import AccountSettings from './AccountSettings.vue'
import SecuritySettings from './SecuritySettings.vue'
import SystemSettings from './SystemSettings.vue'
import UserSettings from './SystemUserSettings.vue'
import RolesSettings from './RolesSettings.vue'
import Billing from './Billing.vue'
import hasPermission from '@/mixins/hasPermission'

export default {
  components: {
    AccountSettings,
    SecuritySettings,
    SystemSettings,
    UserSettings,
    RolesSettings,
    Billing,
  },
  mixins: [hasPermission],
  data() {
    return {
      tabs: [
        { title: 'Account', path: 'account', icon: mdiAccountOutline },
        { title: 'Security', path: 'security', icon: mdiLockOpenOutline },
      ],
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiAccountMultiple,
        mdiAdjust,
        mdiBookmark,
      },
    }
  },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get() {
        return this.$route.query.tab
      },
    },
  },
  mounted() {
    if (this.can('system-setting-list')) {
      this.tabs.push({ title: 'System Settings', path: 'system-settings', icon: mdiApplicationCog })
    }
    if (this.can('service-charge-invoice-list')) {
      this.tabs.push({ title: 'Billing', path: 'service-charge-invoice', icon: mdiBookmark })
    }
    if (this.can('system-user-list')) {
      this.tabs.push({ title: 'System Users', path: 'system-users', icon: mdiAccountMultiple })
    }
    if (this.can('role-list')) {
      this.tabs.push({ title: 'Roles', path: 'roles', icon: mdiAdjust })
    }
  },
}
</script>
