var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-3 mt-2",attrs:{"flat":""}},[_c('h1',{staticClass:"text-center"},[_vm._v(" Billing ")]),_c('v-card-text',{staticClass:"mt-5"},[_c('h4',{staticClass:"mb-2"},[_vm._v(" Billing history ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"loading":_vm.isInvoicesLoading,"items":_vm.invoices,"server-items-length":_vm.pagination.totalItems,"options":_vm.options,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.invoice_number",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.invoice_number))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDay")(item.created_at)))])]}},{key:"item.due_date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDay")(item.due_date)))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("Ksh "+_vm._s(_vm._f("formatCurrency")(item.total_amount)))])]}},{key:"item.users",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.monthly_active_customer.total_active_users)+" customers")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getChipColor(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"small":"","outlined":"","text":"","loading":_vm.viewingInvoicesLoading[item.id],"disabled":_vm.viewingInvoicesLoading[item.id]},on:{"click":function($event){return _vm.viewInvoice(item.id)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")]),_vm._v(" View ")],1),_c('v-btn',{staticClass:"ma-2",attrs:{"small":"","outlined":"","text":"","loading":_vm.loadingInvoices[item.id],"disabled":_vm.loadingInvoices[item.id]},on:{"click":function($event){return _vm.downloadInvoice(item.id)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")]),_vm._v(" Download ")],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }